import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

function SideBar() {
  return (<section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Binny Nanavati</h2>
        <p><a href="mailto:binny.nanavati@gmail.com">binny.nanavati@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Born in India, raised in New Jersey and living in Austin for 20+ years,
        I am Head of Belonging at <a href="https://www.workiva.com/careers/diversity-equity-inclusion-belonging">Workiva</a>,
        a motivational speaker,
        and a debut author with 108 Indian Home Remedies.
        I have a mini-MBA in Finance from Wharton and the
        AT&T School of Business & undergraduate degrees in Computer Science
        and Economics from Rutgers University.
      </p>
      <ul className="actions">
        <li>
          <Link to="/about" className="button">Learn More</Link>
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Binny Nanavati <Link to="/">binnynanavati.com</Link>.</p>
    </section>
  </section>);
}

export default SideBar;
