const routes = [
  {
    index: true,
    label: 'Binny Nanavati',
    path: '/',
  },
  {
    label: 'Book',
    path: '/book',
  },
  {
    label: 'Portfolio',
    path: '/portfolio',
  },
  {
    label: 'Speaking',
    path: '/speaking',
  },

  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Connect',
    path: '/connect',
  },
];

export default routes;
